import axios from 'axios'
import { GET_REPORT } from '@/store/modules/api/endpoints'

export default {
  name: 'reports',
  namespace: true,

  actions: {
    /**
     * Get report content
     */
    async fetchReport({commit}, params) {
      try {
        let response = await axios.get(GET_REPORT.replace(':id', params.id).replace(':hash', params.hash))
        return response.data;
      }
      catch (ex) { throw ex }
    }
  }
}
