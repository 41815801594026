import axios from 'axios'
import { GET_CART, POST_CART_ADD, DELETE_CART, POST_CART_CONFIRM } from '@/store/modules/api/endpoints'


export default {
  name: 'cart',
  namespace: true,

  state: {
    cart: [],
    filters: {
      fulltext: ''
    }
  },

  getters: {
    items (state) {
      return state.cart
    }
  },

  mutations: {
    set(state, payload) {
      state.cart = payload?.data?.items || []
    },
    add(state, id) {
      state.cart.push({id, quantity: 1})
    },
    remove(state, id) {
      state.cart = state.cart.filter(i => id !== i.id)
    },
    clear(state) {
      state.cart = []
    },
    setFilter(state, payload) {
      state.filters.fulltext = payload.fulltext
    }
  },

  actions: {
    /**
     * Get cart products list
     */
    async fetchCart({commit, getters}, force = false) {
      if (!force && getters.items.length) return;

      try {
        let response = await axios.get(GET_CART)
        commit('set', response.data)
      }
      catch (ex) { throw ex }
    },

    /**
     * Add product to cart
     */
    async addToCart({commit, getters}, id = 0) {
      try {
        await axios.post(POST_CART_ADD, {uids: id})
        commit('add', id)
      }
      catch (ex) { throw ex }
    },

    /**
     * Remove one product from cart or clear cart completely
     */
    async removeFromCart({commit}, id = null) {
      try {
        await axios.delete(DELETE_CART.replace(':id', ((id) ? id : '')))
        let result = (id) ? commit('remove', id) : commit('clear')
      }
      catch (ex) { throw ex }
    },

    /**
     * Confirm current cart
     */
    async createOrder({commit}, formData = null) {
      try {
        let response = await axios.post(POST_CART_CONFIRM, formData, {
          headers: {'Content-Type': 'multipart/form-data'}
        })

        commit('clear')

        window.location.href = response.data.data.redirect_pay
      }
      catch (ex) {
        commit('clear')

        throw ex
      }
    }
  }
}
