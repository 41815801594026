<template>
  <div id="app">
    <loader v-if="!isReady"/>

    <component
      v-if="isReady"
      is="main-layout"
    >
      <router-view />
    </component>
  </div>
</template>

<script>
import MainLayout from '@/layouts/MainLayout'
import Loader from '@/components/Loader'

export default {
  components: {
    MainLayout,
    Loader
  },
  data: () => ({
    isReady: false
  }),
  mounted() {
    this.isReady = true;
  }
}
</script>
