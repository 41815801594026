import axios from 'axios'
import { GET_PRODUCTS_PACKAGES, GET_PRODUCTS_SERVICES } from '@/store/modules/api/endpoints'

export default {
  name: 'products',
  namespace: true,

  state: {
    services: [],
    packages: []
  },

  getters: {
    getServices (state) { return state.services },
    getPackages (state, getters) {
      return state.packages.map(p => ({
        ...p,
        included_items: p.included_items.map(i => getters.getServices.find(s => Number(s.id) === Number(i)))
      }))
    },
  },

  mutations: {
    setServices(state, payload) {
      state.services = payload?.data || []
    },
    setPackages(state, payload) {
      state.packages = payload?.data || []
    }
  },

  actions: {
    /**
     * Get list of available services
     */
    async fetchServices({commit, getters}, force = false) {
      if (!force && getters.getServices.length) return;

      try {
        let response = await axios.get(GET_PRODUCTS_SERVICES)
        commit('setServices', response.data)
      }
      catch (ex) { throw ex }
    },

    /**
     * Get list of available services`s packages
     */
    async fetchPackages({commit, getters}, force = false) {
      if (!force && getters.getPackages.length) return;

      try {
        let response = await axios.get(GET_PRODUCTS_PACKAGES)
        commit('setPackages', response.data)
      }
      catch (ex) { throw ex }
    },

    /**
     * Clear list of available products
     */
    clearProductsList({commit}) {
      commit('setServices', [])
      commit('setPackages', [])
    }
  }
}
