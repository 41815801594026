

// ---------------------------------------------------------------------------------------------------------------------
// Auth endpoins
// ---------------------------------------------------------------------------------------------------------------------
export const GET_USER						= `/api/v1/user`
export const PATCH_USER					= `/api/v1/user`
export const POST_USER_SIGNIN		= `/api/v1/user/signin`
export const POST_USER_RESTORE	= `/api/v1/user/restore`
export const POST_USER_LOGOUT		= `/api/v1/user/logout`


// ---------------------------------------------------------------------------------------------------------------------
// Products endpoins
// ---------------------------------------------------------------------------------------------------------------------
export const GET_PRODUCTS_PACKAGES = `/api/v1/products/packages`
export const GET_PRODUCTS_SERVICES = `/api/v1/products/items`


// ---------------------------------------------------------------------------------------------------------------------
// Cart endpoins
// ---------------------------------------------------------------------------------------------------------------------
export const GET_CART						= `/api/v1/cart`
export const POST_CART_ADD			= `/api/v1/cart/add`
export const DELETE_CART				= `/api/v1/cart/:id`
export const POST_CART_CONFIRM	= `/api/v1/cart/confirm`


// ---------------------------------------------------------------------------------------------------------------------
// Orders endpoins
// ---------------------------------------------------------------------------------------------------------------------
export const GET_USER_ORDERS	= `/api/v1/user/orders`
export const DELETE_USER_ORDERS	= `/api/v1/user/orders/:id`


// ---------------------------------------------------------------------------------------------------------------------
// Promocodes endpoins
// ---------------------------------------------------------------------------------------------------------------------
export const GET_PROMOCODES		= `/api/v1/promocodes`
export const POST_PROMOCODES	= `/api/v1/promocodes`


// ---------------------------------------------------------------------------------------------------------------------
// Report endpoints
// ---------------------------------------------------------------------------------------------------------------------
export const GET_REPORT = `/api/v1/report/:id/:hash`
