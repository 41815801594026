<template>
  <div class="topbar">
    <router-link
      class="topbar__logo"
      to="/shop"
      tag="div"
    >
      <svg-logo/>
    </router-link>

    <div class="topbar__profile">
      <template v-if="!isLogin">
        <router-link
          v-if="!isLogin"
          tag="span"
          to="/login"
          class="topbar__profile-login"
        >Войти</router-link>
        <div
          v-if="!isLogin"
          class="topbar__profile-icon waves-effect"
        >
          <svg-profile/>
        </div>
      </template>
      <template v-else>
        <span
          @click.prevent="$bus.emit('logout')"
          class="topbar__profile-login"
        >Выйти</span>
        <div class="topbar__profile-icon active waves-effect">
          <svg-profile/>
        </div>
      </template>

      <div class="topbar__profile-mob">
        <svg-burger
          class="topbar__profile-mob__burger"
          :src="require(`@/assets/svg/burger.svg`)"
          @click.prevent="$bus.emit('toogleSidebar')"
        />
      </div>
      <transition
        name="topbar"
      >
        <div
          class="topbar-modal"
          v-if="isOpen"
        >
          <div
            class="topbar-modal__overlay"
            @click.prevent="$bus.emit('toogleSidebar')"
          ></div>
          <nav class="topbar__profile-mob__nav">
            <img
              class="topbar__profile-mob__logo"
              :src="require(`@/assets/img/logo-basic-mob.png`)"
            />
            <ul>
              <router-link
                v-if="!isLogin"
                to="/"
                tag="li"
                class="topbar__profile-mob__nav-item"
              >
                <span>ГЛАВНАЯ</span>
              </router-link>
              <router-link
                v-if="isLogin"
                tag="li"
                to="/shop"
                class="topbar__profile-mob__nav-item"
              >
                <svg-shop class="topbar__profile-mob__nav-icon"/>
                <span>МАГАЗИН</span>
              </router-link>
              <router-link
                v-if="isLogin"
                tag="li"
                to="/profile/orders"
                class="topbar__profile-mob__nav-item"
              >
                <svg-orders class="topbar__profile-mob__nav-icon"/>
                <span>МОИ ЗАКАЗЫ</span>
              </router-link>
              <router-link
                v-show="isLogin && cart.length"
                tag="li"
                to="/cart"
                class="topbar__profile-mob__nav-item"
              >
                <svg-cart class="topbar__profile-mob__nav-icon"/>
                <span>КОРЗИНА</span>
              </router-link>
              <router-link
                to="/payinfo"
                tag="li"
                class="topbar__profile-mob__nav-item"
              >
                <span>ОПЛАТА</span>
              </router-link>
              <li
                v-if="isLogin"
                @click.prevent="$bus.emit('logout')"
                class="topbar__profile-mob__nav-item"
              >
                <svg-exit class="topbar__profile-mob__nav-icon"/>
                <span>ВЫЙТИ</span>
              </li>
              <router-link
                v-if="!isLogin"
                tag="li"
                to="/login"
                class="topbar__profile-mob__nav-item"
              >
                <svg-profile class="topbar__profile-mob__nav-icon"/>
                <span>Войти</span>
              </router-link>
            </ul>
          </nav>
        </div>
      </transition>
    </div>

    <div
      v-if="isSearchBarVisible"
      class="shop__input-wrap"
    >
      <input
        @input="handleSearch"
        :value="filters.fulltext"
        type="text"
        class="shop__input"
        placeholder="НАЙТИ УСЛУГУ..."
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SvgProfile from '@/assets/svg/profile.svg'
import SvgExit from '@/assets/svg/exit.svg'
import SvgOrders from '@/assets/svg/orders.svg'
import SvgSettings from '@/assets/svg/settings.svg'
import SvgCart from '@/assets/svg/cart.svg'
import SvgShop from '@/assets/svg/shop.svg'
import SvgLogo from '@/assets/svg/logo-basic.svg'
import SvgBurger from '@/assets/svg/burger.svg'
import debounce from 'debounce'

export default {
  name: 'topbar',

  components: {
    SvgProfile,
    SvgLogo,
    SvgBurger,
    SvgExit,
    SvgOrders,
    SvgSettings,
    SvgCart,
    SvgShop
  },

  data: () => ({
    isOpen: false
  }),

  computed: {
    ...mapState({filters: state => state.cart.filters}),
    ...mapGetters(['isLogin']),
    ...mapGetters({cart: 'items'}),
    isSearchBarVisible () {
      return (this.isLogin && this.$route.path === '/shop')
    }
  },

  methods: {
    onToggle () {
      this.isOpen = !this.isOpen
    },
    onClose ()
    {
      this.isOpen = false;
    },
    handleSearch: debounce(function (e) {
      this.$store.commit('setFilter', {fulltext: e.target.value})
    }, 200)
  },

  created() {
    this.$bus.on('toogleSidebar', this.onToggle)
    this.$bus.on('closeSidebar', this.onClose)
  },

  beforeDestroy () {
    this.$bus.off('toogleSidebar', this.onToggle)
    this.$bus.off('closeSidebar', this.onClose)
  }
}
</script>

<style lang="scss">
.topbar {
  position: relative;
  display: flex;
  justify-content: space-between;

  &:after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    background: #E0E0E0;
  }

  @include sm {
    position: sticky;
    top: 0;
    z-index: 10;
    flex-wrap: wrap;
    background: #F8F8FA;
    border-radius: 35px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.01);
    padding: rem-sm(30px) rem-sm(10px);
    &:after {
      display: none;
    }
  }

  @include md {
    padding: rem-md(50px) rem-md(10px);
  }

  @include lg {
    width: 70%;
    max-width: 1280px;
    margin: 0 auto;
    padding: rem-lg(50px) 0;
  }
}

.topbar__logo {
  text-align: left;
  cursor: pointer;
  min-width: 250px;
  transition: transform .15s ease-in;

  &:hover {
    transform: translateX(-2px);
  }
}

.topbar__profile {
  display: flex;
  align-items: center;

  &:hover {
    .topbar__profile-login {
      color: rgb(47, 107, 240);
    }
  }
}

.topbar__profile-login {
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
  transition: color .1s ease-in;
  @include sm {
    display: none;
  }
}

.topbar__profile-icon {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #9095A6;
  cursor: pointer;
  z-index: 10;

  &:before {
    position: absolute;
    content: '';
    display: block;
    padding: 4px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: scale(1.5);
    opacity: 0;
    z-index: 1;
    transition: all .2s ease-in;
  }

  &.active {
    background: rgb(47, 107, 240);
  }

  .topbar__profile & {
    display: flex;
  }

  svg {
    width: 30px;
    height: 30px;
  }

  &:hover {
    filter: brightness(110%);

    &:before {
      opacity: 1;
      transform: scale(1);
      box-shadow: 0px 0px 3px 4px rgba(47, 107, 240, 0.3)
    }
  }
  @include sm {
    margin-left: rem-sm(30px);

    .topbar__profile & {
      display: none;
    }
  }
  @include md {
    margin-left: rem-md(40px);
  }
  @include lg {
    margin-left: rem-lg(60px);
  }
}

.topbar__profile-mob {
  display: none;
  @include sm {
    display: flex;
  }
}

.topbar__profile-mob__nav {
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 10;
  border-radius: 33px 33px 33px 0;
  min-height: 100vh;
  transform: translateX(0);
  opacity: 1;

  ul {
    display: flex;
    flex-direction: column;
  }

  .topbar__profile-mob__nav-item:last-child {
    margin-top: 60px;
  }
}

.topbar__profile-mob__nav-item {
  margin-bottom: 40px;

  span {
    @include sm {
      font-size: 16px;
    }
    @include md {
      font-size: rem-md(16px);
    }
  }
}

.topbar-modal__overlay {
  transform: translateX(50px);
}

.topbar__profile-mob__nav-icon {
  width: 30px;
  margin-right: 15px;
  fill: #2F6BF0;
}

.topbar__profile-mob__nav-item {
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.topbar__profile-mob__logo {
  margin-bottom: 50px;
  @include sm {
    width: rem-sm(300px);
    max-width: 300px;
  }
}

.topbar-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.topbar-modal__overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0,0,0, .1);
}

nav {
  .topbar__profile-mob & {
    width: auto;
    height: auto;
    line-height: 1;
    background-color: #ffffff;
    color: unset;
  }
}
</style>
