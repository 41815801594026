import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {layout: 'main'},
    component: () => import('../views/Home.vue')
  },
  {
    path: '/payinfo',
    name: 'payinfo',
    meta: {layout: 'main'},
    component: () => import('../views/PayInfo.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {layout: 'main'},
    component: () => import('../views/Login.vue')
  },
  {
    path: '/restore',
    name: 'restore',
    meta: {layout: 'main'},
    component: () => import('../views/RestorePassword.vue')
  },
  {
    path: '/shop',
    name: 'shop',
    meta: {layout: 'main', authRequired: true},
    component: () => import('../views/Shop.vue')
  },
  {
    path: '/profile',
    meta: {layout: 'main', authRequired: true},
    component: () => import('../views/ProfileLayout.vue'),
    children: [
      {
        path: '',
        meta: {layout: 'main', authRequired: true},
        component: () => import('../views/Profile.vue')
      },
      {
        path: 'orders',
        meta: {layout: 'main', authRequired: true},
        component: () => import('../views/UserOrders.vue')
      },
      {
        path: 'promocodes',
        meta: {layout: 'main', authRequired: true},
        component: () => import('../views/UserPromocodes.vue')
      },
      {
        path: 'settings',
        meta: {layout: 'main', authRequired: true},
        component: () => import('../views/UserSettings.vue')
      },
    ]
  },
  {
    path: '/cart',
    name: 'cart',
    meta: {layout: 'main', authRequired: true},
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/report/:report_id/:report_hash',
    name: 'report',
    meta: {layout: 'main', authRequired: true},
    props: (route) => { return {...route.params} },
    component: () => import('../views/Report.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let isAuthRequired = to.matched.some(record => record?.meta?.authRequired)
  let isLogin = store.getters.isLogin

  if (isAuthRequired && !isLogin) {
    (from.path === '/login') ? next(false) : next({path: '/login', query: {redirect: to.fullPath}})
  }
  else if (isLogin && to.path === '/login') {
    next('/shop')
  }
  else {
    next()
  }
})

export default router
