import Vue from 'vue'
import App from './App.vue'
import VueBus from 'vue-bus'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import VTooltip from 'v-tooltip'
import router from './router'
import store from './store'
import axios from 'axios'
import { formatPrice, formatDate } from './filters'
import 'materialize-css/dist/js/materialize.min'
import VueJivosite from '@bchteam/vue-jivosite'
import VueGtm from '@gtm-support/vue2-gtm';

Vue.use(VueBus)
Vue.use(Vuelidate)
Vue.use(VTooltip)
Vue.use(VueTheMask)
Vue.use(VueJivosite, { widgetId: 'MsYXrhOZFn' })
Vue.use(VueGtm, {
  id: 'GTM-KT7JQK9',
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router
})

Vue.filter('formatPrice', formatPrice)
Vue.filter('formatDate', formatDate)


Vue.config.productionTip = false

axios.defaults.headers.common['Accept'] = 'application/vnd.provereno.v1+json';

axios.interceptors.response.use(
  response => {
    return response;
  },
  function(error) {
    if (error.response.status === 403 && error.response?.data?.errors?.unauthorized) {
      store.commit('setUser', false)
      store._vm.$bus.emit('logout')
    }
    return Promise.reject(error.response);
  }
);

var app;
function init() {
  if (app) return;

  app = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

store
  .dispatch('getUserProfile')
  .catch(() => {})
  .finally(() => {
    init()
  });



