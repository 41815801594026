import axios from 'axios'
import {
  POST_USER_SIGNIN,
  POST_USER_LOGOUT,
  GET_USER,
  PATCH_USER,
  POST_USER_RESTORE
} from '@/store/modules/api/endpoints'

export default {
  name: 'user',
  namespace: true,

  state: {
    user: JSON.parse(localStorage.getItem('user')) || false
  },

  getters: {
    getUser (state) { return state.user },
    isLogin (state) { return !!(state.user?.login) }
  },

  mutations: {
    setUser(state, payload) {
      state.user = payload?.data
      let t = (state.user) ? localStorage.setItem('user', JSON.stringify(payload)) : localStorage.removeItem('user');
    }
  },

  actions: {
    /**
     * Get user profile info
     */
    async getUserProfile({commit}) {
      try {
        let response = await axios.get(GET_USER)
        commit('setUser', response.data)
      }
      catch (ex) { throw ex }
    },

    /**
     * Signin user
     */
    async loginUser({commit}, payload) {
      try {
        let response = await axios.post(POST_USER_SIGNIN, payload)
        commit('setUser', response.data)
      }
      catch (ex) { throw ex }
    },

    /**
     * Logout user
     */
    async logoutUser({commit}) {
      try {
        await axios.post(POST_USER_LOGOUT)
      }
      catch (ex) { throw ex }
      finally {
        commit('setUser', false)
      }
    },

    /**
     * Set random user password
     */
    async restorePassword({commit}, payload) {
      try {
        await axios.post(POST_USER_RESTORE, payload);
      }
      catch (ex) { throw ex; }
    }
  }
}
