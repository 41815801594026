import axios from 'axios'
import { GET_PROMOCODES, POST_PROMOCODES } from '@/store/modules/api/endpoints'


export default {
  name: 'promocodes',
  namespace: true,

  state: {
    promocode: null
  },

  getters: {
    getPromocode (state) { return state.promocode },
  },

  mutations: {
    setPromocode(state, payload) {
      state.promocode = payload?.data?.promocode || null
    },
  },

  actions: {
    /**
     * Apply promocode
     */
    async fetchPromocode({commit}) {
      try {
        let response = await axios.get(GET_PROMOCODES)
        commit('setPromocode', response.data)
      }
      catch (ex) { throw ex }
    },

    /**
     * Apply promocode
     */
    async applyPromocode({commit, getters}, promocode = '') {
      try {
        await axios.post(POST_PROMOCODES, {promocode: promocode})
        commit('setPromocode', {data: {promocode: promocode}})
      }
      catch (ex) { throw ex }
    }
  }
}
