import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/user'
import products from '@/store/modules/products'
import orders from '@/store/modules/orders'
import cart from '@/store/modules/cart'
import promocodes from '@/store/modules/promocodes'
import reports from '@/store/modules/reports'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    products,
    cart,
    orders,
    promocodes,
    reports
  },
  strict: process.env.NODE_ENV !== 'production'
})
