import axios from 'axios'
import { GET_USER_ORDERS, DELETE_USER_ORDERS } from '@/store/modules/api/endpoints'

export default {
  name: 'orders',
  namespace: true,

  state: {
    list: []
  },

  getters: {
    getOrders (state) { return state.list }
  },

  mutations: {
    setOrders(state, payload) {
      state.list = payload?.data || []
      state.list = state.list.sort((a,b) => a>b?1:-1)
    },
    deleteOrder(state, payload) {
      state.list = state.list.filter(i => i.id!==payload)
    }
  },

  actions: {
    /**
     * Get list of user orders
     */
    async fetchOrders({commit, getters}, force = false) {
      if (!force && getters.getOrders.length) return;

      try {
        let response = await axios.get(GET_USER_ORDERS)
        commit('setOrders', response.data)
      }
      catch (ex) { throw ex }
    },

    async removeOrder({commit}, id = null) {
      try {
        await axios.delete(DELETE_USER_ORDERS.replace(':id', ((id) ? id : '')))
        commit('deleteOrder', id)
      }
      catch (ex) { throw ex }
    }
  }
}
