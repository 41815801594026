<template>
  <div class="main-layout">
    <topbar/>

    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Topbar from '@/components/Topbar'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MainLayout',

  components: {
    Topbar
  },

  computed: {
    ...mapGetters(['isLogin']),
    ...mapGetters({cart: 'items'})
  },

  methods: {
    ...mapActions(['logoutUser', 'clearProductsList']),
    async logoutHandler() {
      try {
        if (this.isLogin) {
          await this.logoutUser()
        }
      }
      catch (ex) {}
      finally {
        this.clearProductsList()
        this.$router.push('/login')
      }
    }
  },

  watch: {
    $route(to, from) {
      this.$bus.emit('closeSidebar')
    }
  },

  mounted() {
    this.$bus.on('logout', this.logoutHandler)
  },
  beforeDestroy() {
    this.$bus.off('logout', this.logoutHandler)
  }
}
</script>

<style scoped>
  .main-layout {
    height: 100vh;
    overflow-x: scroll;
  }
</style>
